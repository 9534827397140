
import { mapActions, mapGetters } from 'vuex'

export default {
	data() {
		return {
			classSwitcher: null,
			modalComponentVariable: false,
			modalTitleVariable: '',
			modalSubtitleVariable: ''
		}
	},
	computed: {
		...mapGetters({
			modalTitle: 'modal/modal-main/modalTitle',
			modalSubTitle: 'modal/modal-main/modalSubTitle',
			modalComponent: 'modal/modal-main/modalComponent'
		}),
	},
	methods: {
		...mapActions({
			closeModal: 'modal/modal-main/closeModal'
		})
	},
	mounted() {
		if (this.modalComponent === 'modal-credit') this.classSwitcher = 'modal--mini'
		if (this.modalComponent === 'modal-timer') this.classSwitcher = 'modal--timer'
		if (this.modalComponent === 'modal-fotoZum') this.classSwitcher = 'modal--fotoZum'
		
		if (this.modalComponent) {
			this.modalComponentVariable = this.modalComponent
		}
		if (this.modalTitle) {
			this.modalTitleVariable = this.modalTitle
		}
		if (this.modalSubTitle) {
			this.modalSubtitleVariable = this.modalSubTitle
		}
	},
	watch: {
		modalComponent(value) {
			// if (value === 'modal-credit') this.classSwitcher = 'modal--mini'
			// if (value === 'modal-timer') this.classSwitcher = 'modal--timer'
			// if (value === 'modal-fotoZum') this.classSwitcher = 'modal--fotoZum'
			// if (!value) setTimeout(() => this.classSwitcher = null)


		}
	}

}
