
import { mapGetters, mapActions } from "vuex";

export default {
    computed: {
        ...mapGetters({
            settings: 'settings/settings/settings',
            isNight: "settings/settings/isNight",
        }),
    },
    methods: {
        ...mapActions({
            openModal: 'modal/modal-main/openModal',
        }),
        callback() {
            let payload = {
                modal_data: {},
                modal_component: 'modal-timer',
                modal_title: 'ЗАЯВКА НА АВТОКРЕДИТ',
                modal_sub_title: {}
            }
            this.openModal(payload)
        }
    }

}
