
import { mapActions, mapGetters } from 'vuex'

export default {
	methods: {
		...mapActions({
			closeModal: 'modal/modal-main/closeModal'
		}),
	},
	computed: {
		...mapGetters({
			modal: 'modal/modal-main/modal',
			modalMenu: 'modal/modal-menu/modalMenu',
			modalComponent: 'modal/modal-main/modalComponent'

		}),
	}
}
